import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import './../App.css';
import "./../vendor/App.scss"
import '@snr1kor/jupiter-engine-native';
import { LaunchComponent } from "./LaunchComponent";
import { HeaderBar } from "./../navbar/HeaderBar"
import * as API from './../services/APICalls';
import CircularProgress from '@material-ui/core/CircularProgress';


export const Main = () => {
  
  const [loadApp, setLoadApp] = useState(false);
  const [getUserName, setGetUserName] = useState();
  const [loadLogin, setLoadLogin] = useState(sessionStorage.getItem("appLoad"));
  const getAccessTokenValue = useSelector(state => state.AccessToken.data);
  // localStorage.getItem("accessToken");
  const getExistingToken = localStorage.getItem("accessToken");
  const [loader, setLoader] = useState(false);
  const [flag, setFlag] = useState(true);
  let codeValue = '';
  const dispatch = useDispatch();
  const loadJupiterApp = () => {
    setLoadApp(true)
    setLoadLogin(true)
    sessionStorage.setItem("appLoad", true)
  }

  const getParameterByName = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  if (getParameterByName('code')) {
    codeValue = getParameterByName('code');
  }

  const getAccessToken = async (codeValue) => {
    setFlag(false)
    dispatch(API.fetchToken(codeValue));
  }
  
  useEffect(()=>{
  if(getExistingToken){
    const decodeAccessToken = jwt_decode(getExistingToken);
    const getExpTime = decodeAccessToken["exp"];
    if(getExpTime <= Math.floor(new Date/1000)){
      localStorage.clear();
      sessionStorage.clear();
    }
  }
  },[])

  useEffect(() => {
    if (getAccessTokenValue) {
      localStorage.setItem("accessToken", getAccessTokenValue);
      const extractUser = jwt_decode(getAccessTokenValue);
      setGetUserName(extractUser["p-email"])
      setLoader(false)
    }
  }, [getAccessTokenValue])

  useEffect(() => {
    if (codeValue || localStorage.getItem("accessToken") ) {
      getAccessToken(codeValue);
    }
  }, [codeValue,localStorage.getItem("accessToken")])

  // useEffect(() => {
  //   if (performance.navigation.type === 1) {
  //     localStorage.setItem("reload",window.location.pathname)
  //   } 
  // },[])

  return (
    <div className={`App ${!loadLogin ? '' : ''}`} id="App">
      {!loader && flag && !getAccessTokenValue && (<Fragment>
        <HeaderBar />
        <LaunchComponent tokenFromURLParam={""} loadJupiterApp={loadJupiterApp} setLoader={setLoader} setFlag={setFlag} />
      </Fragment>)
    }
    {loader && <CircularProgress className="loader" />}
      {!loader && getAccessTokenValue &&
        <jupiter-sat
          id={`mfe-support-portal`}
          context={`{"user":"${getUserName ? getUserName : ""}","customer":"3M","apiToken":"${getAccessTokenValue ? getAccessTokenValue : ""}"}`}
          hostName={window._env_.REACT_APP_MFE_APP_NAME}
          version={window._env_.REACT_APP_MFE_VERSION}
          stack={window._env_.REACT_APP_MFE_ENV}
          token={getAccessTokenValue ? getAccessTokenValue : ""}
          authMode={"paladin"}
        />
      }

    </div>
  );
}
