export const FETCH_LIST_TICKETS = 'FETCH_LIST_TICKETS';
export const FETCH_TICKET_BY_ID = 'FETCH_TICKET_BY_ID';
export const FETCH_ACCESSTOKEN = 'FETCH_ACCESSTOKEN';
export const FETCH_ATTACHMENT = 'FETCH_ATTACHMENT';
export const FETCH_DROPDOWN_OPTIONS='FETCH_DROPDOWN_OPTIONS';
export const FETCH_STATUS_MESSAGE='FETCH_STATUS_MESSAGE';
export const FETCH_TOKEN='FETCH_TOKEN';
export const FETCH_DELETE_SUCCESS='FETCH_DELETE_SUCCESS';
export const FETCH_DELETE_ATTCHMENT_SUCCESS='FETCH_DELETE_ATTCHMENT_SUCCESS';
export const FETCH_ADD_COMMENTS='FETCH_ADD_COMMENTS';
export const FETCH_UPDATED_COMMENTS='FETCH_UPDATED_COMMENTS';
export const FETCH_COMMENTS_BY_ID = 'FETCH_COMMENTS_BY_ID';
export const FETCH_REQUEST_TYPE='FETCH_REQUEST_TYPE';