import { createMuiTheme } from '@material-ui/core/styles';

export const CustomTheme = createMuiTheme({
    palette: {
      primary: {
        main: '#0171ad',
        contrastText: 'white',
      }},
      overrides: {
        MuiInputLabel: {
            root: {
                // color: 'black',
                fontSize: 16,
            },
        },
    }
});



