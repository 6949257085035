 
  export function getAccessToken(data) {
    return {
      type: "FETCH_ACCESSTOKEN",
      payload: data
    };
  }
  
  export function getToken(data){
    return {
      type:"FETCH_TOKEN",
      value : data
    };
  }
