import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from 'redux-thunk';
import './index.css';
import App from './App';
import rootReducer from "./reducers/rootReducer";
import reportWebVitals from './reportWebVitals';
import {jssPreset, MuiThemeProvider, StylesProvider} from '@material-ui/core/styles';
import { CustomTheme } from './vendor/customTheme';
import {customFont} from "./theme/customFont";


const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  // other store enhancers if any
);

const store=createStore(rootReducer,enhancer);


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <MuiThemeProvider theme={customFont, CustomTheme}>
      <App />
    </MuiThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
