import React, {useState} from 'react';
import {Grid, CardContent, Typography, makeStyles,Paper} from '@material-ui/core';
import {Container, Row, Col} from "react-bootstrap";
import {Accordion, Card} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {Button} from "@material-ui/core";
import { useEffect } from 'react';
// import '@snr1kor/jupiter-engine';

const useStyles = makeStyles((theme)=>({
  root: {
    fontWeight: 700
  },
  paper: {
    padding: theme.spacing(2),
    // fontFamily:`"Hp Simplified", "Helvetica Neue", sans-serif`
  }
}));
export const LaunchComponent = (props) => {
  const { tokenFromURLParam, loadJupiterApp,setLoader,setFlag } = props;
  const classes = useStyles();
  const [locale, setLocale] = useState(sessionStorage.getItem('locale'));
  const [t] = useTranslation('common');
  const getParameterByName = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
  let checkForEmail = getParameterByName('email')
  let checkForUserType = getParameterByName('userType');
  let checkForAsset = getParameterByName('asset')
  let checkForTicketId = getParameterByName('caseid');
  let checkIfItBelogsTo = checkForEmail.indexOf('@hp.com');
  if (checkForAsset !== "" ){
    localStorage.setItem("assetNameFromURL",checkForAsset);
  }
  if (checkForTicketId !== "" ){
    localStorage.setItem("redirectTo","/details");
    sessionStorage.setItem("caseID",checkForTicketId);
  }
  if (checkForEmail !== "" && checkIfItBelogsTo > -1) {
    window.location.replace(`${window._env_.REACT_APP_PALADIN_URL}?id=${window._env_.REACT_APP_PALADIN_APP_KEY}`)
  } else if (checkForEmail !== "") {
    window.location.replace(`${window._env_.REACT_APP_PALADIN_URL_NON_HP_USER}?id=${window._env_.REACT_APP_PALADIN_APP_KEY}`)
  }

  if (checkForUserType === "Internal") {
    window.location.replace(`${window._env_.REACT_APP_PALADIN_URL}?id=${window._env_.REACT_APP_PALADIN_APP_KEY}`)
  } else if (checkForUserType === "External") {
    window.location.replace(`${window._env_.REACT_APP_PALADIN_URL_NON_HP_USER}?id=${window._env_.REACT_APP_PALADIN_APP_KEY}`)
  }

  const handelLoginNonHpUser = () => {
    loadJupiterApp()
    setLoader(true)
    localStorage.removeItem("assetNameFromURL");
    window.location.replace(`${window._env_.REACT_APP_PALADIN_URL_NON_HP_USER}?id=${window._env_.REACT_APP_PALADIN_APP_KEY}`)
  }
  const handelLoginHpUser = () => {
    loadJupiterApp()
    setLoader(true)
    window.location.replace(`${window._env_.REACT_APP_PALADIN_URL}?id=${window._env_.REACT_APP_PALADIN_APP_KEY}`)
  }
  return (
     
        <Grid container style={{ padding: "80px 10px", justifyContent:"center",height:"100vh"}}>
               <Grid item xs={4}>
                <Paper className={classes.paper} style={{margin:"2px"}}>
                  <Card className="mb-4">
                      <Card.Header style={{ textAlign: "center",fontWeight:"800",fontSize:"20px" }}>
                          Customer Login
                      </Card.Header>
                      <Card.Body style={{textAlign:"center"}}>
                          <Card.Text>Login with HPID</Card.Text>
                          <Accordion className="pb-2">
                          </Accordion>
                          <Button  variant="contained" color="primary" onClick={handelLoginNonHpUser} className="customer_Login">
                              Login
                          </Button>
                      </Card.Body>
                  </Card>
                  </Paper>
              </Grid> 

          
            <Grid item xs={4}>
              <Paper className={classes.paper} style={{margin:"2px"}}>
              <Card className="mb-4">
                <Card.Header style={{ textAlign: "center",fontWeight:"800",fontSize:"20px" }}>
                  HP Employee Login
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  <Card.Text>Login with your employee credentials</Card.Text>
                  <Accordion className="pb-2">
                  </Accordion>
                  <Button variant="contained" color="primary" onClick={handelLoginHpUser} className="employee_Login">
                    Login
                  </Button>
                </Card.Body>
              </Card>
              </Paper>
            </Grid>
           
        </Grid>
       
  );

}