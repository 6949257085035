import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';

import './App.css';
import "./vendor/App.scss"


import {Health} from "./component/Health";
import {Main} from "./component/Main";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/health" exact component={Health} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
