import { FETCH_TOKEN } from '../actions/types';

const initialState = {
  data: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TOKEN:
      if (action && action.value && action.value.data && action.value.data.token !== "") {
        console.log(action.value.data.token)
        return {
          ...state,
          data: action.value.data.token
        };
      }
      else{
        return {
          ...state,
          data: localStorage.getItem("accessToken")
        };
      }
    default:
      return state;
  }
}