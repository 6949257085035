import { getAccessToken, getToken } from "../../actions/getListTickets";
import axios from 'axios';

  
export function fetchToken(extraParams) {

    return (dispatch) => {
      axios.get(`${window._env_.REACT_APP_PALADIN_URL_WITH_CODE}`+extraParams,
        {
          headers: {
            'x-api-key': `${window._env_.REACT_APP_PALADIN_APP_KEY}`
          }
        }
      )
        .then(response => response)
        .then(data => {
          console.log(data,"indexDATA")
           dispatch(getToken(data))
        })
    }
  
}