import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import hpLogo from './../images/hp_logo.png'
import enFlag from "./../assets/img/us.svg";
import deFlag from "./../assets/img/germany.svg";
import frFlag from "./../assets/img/france.svg";
import itFlag from "./../assets/img/italy.svg";
import esFlag from "./../assets/img/poland.svg";
import trFlag from "./../assets/img/turkey.svg";
import spFlag from "./../assets/img/spain.svg";
import ruFlag from "./../assets/img/russia.svg";
import MenuItem from '@material-ui/core/MenuItem';
import { Nav, Navbar, DropdownButton, Dropdown } from "react-bootstrap";
import { FaEllipsisV, FaUserAlt, FaLock, FaHome } from "react-icons/fa";
import '../vendor/App.scss';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { ButtonBase, createMuiTheme, FormControl, InputLabel, MuiThemeProvider, Select } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

export const HeaderBar = (props) => {
  const classes = useStyles();
  const { data, assetEmail, dropDownOptions, onChangeLanguage, ResourceHelper, handleLogout, extractUserName, tokenFromURLParam, setNavigation, assetName, getData, setAssetName } = props;
  const [locale, setLocale] = useState(sessionStorage.getItem('locale'));
  const [src, setSrc] = useState(hpLogo);
  const [error, setError] = useState(false);
  const [onClickFlag, setOnClickFlag] = useState(false);
  // const [languageOptions, setLanguageOptions] = useState([]);
  const [languageValue, setLanguageValue] = useState(sessionStorage.getItem('locale'));
  // let dropDownOptionsStatus = dropDownOptions.items.status;
  // let dropdown = dropDownOptions;
  const [t] = useTranslation('common');
  const styles = createMuiTheme({
    overrides: {
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "none"
          }
        },
        icon: {
          color: "white"
        }
      }
    }
  });
  // useEffect(() => {
  //   if (dropDownOptionsStatus == "200") {

  //     setLanguageOptions(dropDownOptions.items.data.projects[0].issuetypes[0].fields.customfield_11409.allowedValues.map(value => ({
  //       value: value.value, label: value.value
  //     }
  //     )))
  //   }

  // }, [dropDownOptions])
  const languageOptions = [
    { value: 'English', label: 'English' },
    { value: 'French', label: 'French' },
    { value: 'German', label: 'German' },
    { value: 'Spanish', label: 'Spanish' },

  ];
  useEffect(() => {
    sessionStorage.setItem("language", languageOptions && languageOptions[0] && languageOptions[0].value)
  }, [languageOptions])
  const onChangeHandler = (e) => {
    // ----Please uncomment this code for img----

    // let languageflag="";
    // switch (e.target.value) {
    //   case "en":
    //      languageflag=<img src={enFlag} width="16" height="11"/>
    //      break;

    //   default:
    //     break;
    // }
    // setLanguageValue(languageflag);

    setLanguageValue(e.target.value);
    let languageName = "";
    switch (e.target.value) {
      case "en":
        languageName = "English"
        break;
      case "fr":
        languageName = "French"
        break;
      case "de":
        languageName = "German"
        break;
      case "it":
        languageName = "Italian"
        break;
      case "es":
        languageName = "Polish"
        break;
      case "ru":
        languageName = "Russian"
        break;
      case "sp":
        languageName = "Spanish"
        break;
      case "tr":
        languageName = "Turkish"
        break;
      default:
        languageName = "English"
        break;
    }
    onChangeLanguage(languageName);
  }
  const onErrorImage = () => {
    if (!error) {
      setError(true)
      setSrc(hpLogo)
    }
  }
  const loadLogo = data && data.asset !== undefined && data.asset === "DCC" ? "" : <span className="brand-logo"></span>;
  const showEmail = data && data.asset !== undefined && data.asset === "DCC" ? "" : assetEmail;
  const checkflag = (e) => {
    switch (e) {
      case "English":
        return <img src={enFlag} width="19" height="14" />
      case "French":
        return <img src={frFlag} width="19" height="14" />
      case "German":
        return <img src={deFlag} width="19" height="14" />
      case "Italian":
        return <img src={itFlag} width="19" height="14" />
      case "Polish":
        return <img src={esFlag} width="19" height="14" />
      case "Russian":
        return <img src={ruFlag} width="19" height="14" />
      case "Spanish":
        return <img src={spFlag} width="19" height="14" />
      case "Turkish":
        return <img src={trFlag} width="19" height="14" />
      default:
        return "en";
    }
  }
  const check = (e) => {
    switch (e) {
      case "English":
        return "en"
      case "French":
        return "fr"
      case "German":
        return "de"
      case "Italian":
        return "it"
      case "Polish":
        return "es"
      case "Russian":
        return "ru"
      case "Spanish":
        return "sp"
      case "Turkish":
        return "tr"
      default:
        return "en";
    }
  }
  const handleClick = () => {

    // window.location.reload(false);
    setOnClickFlag(true);
    sessionStorage.setItem("navigation", "default")
    setNavigation('default')
    localStorage.setItem('assetName', "All");
    setAssetName(localStorage.getItem('assetName'))


  }

  useEffect(() => {
    if (onClickFlag) {
      getData();
    }
    setOnClickFlag(false);
  }, [assetName])
  return (
    <MuiThemeProvider theme={styles} >
      <Navbar collapseOnSelect expand="lg"  variant="dark" fixed="top" className="headerBar">
        <Navbar.Brand onClick={handleClick} className="Mouse ">
          {loadLogo}&nbsp;Manage Service Portal
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            {tokenFromURLParam !== '' &&
              <Nav.Item>


                {/* <select name="select" placeholder="Language" onChange={onChangeHandler}    >
              {languageOptions.map((language) => (
                < option id = { language.value }
                selected = { locale ===  check(language.value)
                // (language.value === 'English' ? 'en' : language.value === 'French' ? 'fr' : language.value === 'German' ? 'de' : language.value === 'Italian' ? 'it' : language.value === 'Polish' ? 'es' : language.value === 'Russian' ? 'ru' : language.value === 'Spanish' ? 'sp' : language.value === 'Turkish' ? 'tr' : 'en') 
                ? "selected" : ''} data-img_src={hpLogo} className="brand-logo">
                <img src={enFlag} width="16" height="11"/>{check(language.value)}
                </option>
              ))}
            </select> */}
              </Nav.Item>
            }
            {/* <Nav.Item className="mss-user">
          {showEmail}
        </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>

      </Navbar >
    </MuiThemeProvider>
    //   <AppBar style={{ background: '#ffffff',position:"relative",boxShadow:"0px 0px 0px 0px rgb(0 0 0 / 100%)",color:"#000000",padding:"15px" }}>
    //   <Toolbar>
    //     <Typography variant="h4" className={classes.title}>
    //     {ResourceHelper.getResourceValueByKey(locale,"MANAGED_SERVICE_SUPPORT","Managed Service Support")}
    //     </Typography>
    //     {/* <img src={hpLogo}  width="48" height="44"/> */}
    //    {loadLogo}

    //   </Toolbar>
    // </AppBar> 

  );
}